<script>
import "@vueform/multiselect/themes/default.css";
import "flatpickr/dist/flatpickr.css";
import Swal from "sweetalert2";

import Layout from "../../layouts/main.vue";
import appConfig from "../../../app.config";
import PageHeader from "@/components/page-header";
import Axios from "@/config/axios";
import animationData from "@/components/widgets/msoeawqm.json";
import Lottie from "@/components/widgets/lottie.vue";
import store from "../../state/store";

export default {
  page: {
    title: "Gestion des catégories",
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  data() {
    return {
      title: "Gestion des catégories",
      items: [
        {
          text: "Ecommerce",
          href: "/",
        },
        {
          text: "Gestion des catégories",
          active: true,
        },
      ],
      page: 1,
      perPage: 8,
      pages: [],
      value: null,
      statuscategory: "All",
      value1: null,
      searchQuery: null,
      config: {
        wrap: true, // set wrap to true only when using 'input-group'
        altFormat: "M j, Y",
        altInput: true,
        dateFormat: "d M, Y",
        mode: "range",
      },
      timeConfig: {
        enableTime: false,
        dateFormat: "d M, Y",
      },
      date: null,
      date2: null,
      defaultOptions: {
        animationData: animationData,
      },
      categories: [],
      isStatus: null,
      isPayment: null,
    };
  },
  components: {
    Layout,
    PageHeader,
    lottie: Lottie,
  },
  created() {},
  filters: {
    trimWords(value) {
      return value.split(" ").splice(0, 20).join(" ") + "...";
    },
  },

  mounted() {
    var checkAll = document.getElementById("checkAll");
    if (checkAll) {
      checkAll.onclick = function () {
        var checkboxes = document.querySelectorAll(
          '.form-check-all input[type="checkbox"]'
        );
        if (checkAll.checked == true) {
          checkboxes.forEach(function (checkbox) {
            checkbox.checked = true;
            checkbox.closest("tr").classList.add("table-active");
          });
        } else {
          checkboxes.forEach(function (checkbox) {
            checkbox.checked = false;
            checkbox.closest("tr").classList.remove("table-active");
          });
        }
      };
    }
  },
  async beforeRouteEnter() {
    let categories = [];
    await Axios.get("/admin/course-categories")
      .then(({ data }) => {
        categories = data.data;
      })
      .catch((er) => {
        console.log(er);
      });

    localStorage.setItem("@COURSES_CATEGORIES", JSON.stringify(categories));
  },
  beforeMount() {
    this.categories = JSON.parse(localStorage.getItem("@COURSES_CATEGORIES") || "[]");
  },
  methods: {
    async getcategories() {
      await Axios.get("/admin/course-categories")
        .then(({ data }) => {
          this.categories = data.data;
        })
        .catch((er) => {
          console.log(er);
        });
    },
    editdata(data) {
      let result = this.categories.findIndex((o) => o.id == data.id);
      console.log(result);

      document.getElementById("edtcategoryId").value =
        this.categories[result].id;
      document.getElementById("editname").value = this.categories[result].name;
    },
    updatecategory() {
      let result = this.categories.findIndex(
        (o) => o.id == document.getElementById("edtcategoryId").value
      );
      this.categories[result].name = document.getElementById("editname").value;

      document.getElementById("edtclosemodal").click();

      Axios.put(
        `/admin/product-categories/${
          document.getElementById("edtcategoryId").value
        }`,
        this.categories[result]
      )
        .then(() => {
          this.getcategories();
        })
        .catch((er) => {
          console.log(er);
        });
    },
    createcategory() {
      const name = document.getElementById("createname").value;

      document.getElementById("createclosemodal").click();

      Axios.post(`/admin/product-categories`, { name, is_course: true })
        .then(() => {
          this.getcategories();
        })
        .catch((er) => {
          console.log(er);
        });
    },
    deletedata(event) {
      Swal.fire({
        title: "Êtes-vous certain ?",
        text: "Cette opération est irréversible !",
        icon: "warning",
        showCancelButton: true,
        cancelButtonColor: "#f46a6a",
        confirmButtonColor: "#34c38f",
        confirmButtonText: "Oui, supprimer !",
      }).then((result) => {
        if (result.value) {
          this.categories.splice(this.categories.indexOf(event), 1);
          Axios.delete(`/admin/product-categories/${event.id}`)
            .then(() => {
              this.getcategories();
            })
            .catch((er) => {
              console.log(er);
            });
          Swal.fire("Supprimé !", "La catégorie a été effacée.", "success");
        }
      });
    },
    updateFactor(category_id, direction) {
      const init = this.categories.findIndex((item) => item.id == category_id);

      let target = init + 1;

      if (direction == "up") {
        target = init - 1;
      }

      [this.categories[init], this.categories[target]] = [
        this.categories[target],
        this.categories[init],
      ];

      const temp = this.categories.map((category, index) => ({
        ...category,
        factor: index + 1,
      }));

      const data = temp.map((category) => ({
        id: category.id,
        factor: category.factor,
      }));

      store.dispatch("setGlobalLoading", true);
      Axios.put("/admin/product-categories/update-factors", {
        categories: data,
      })
        .then(({ data }) => {
          this.categories = data.data;
        })
        .finally(() => {
          store.dispatch("setGlobalLoading", false);
        });
    },
  },
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col-lg-12">
        <div class="card" id="orderList">
          <div class="card-header border-0">
            <div class="d-flex align-items-center">
              <h5 class="card-title mb-0 flex-grow-1">
                Catégories de formations
              </h5>
              <div class="flex-shrink-0">
                <button
                  type="button"
                  class="btn btn-success add-btn"
                  data-bs-toggle="modal"
                  id="create-btn"
                  data-bs-target="#showModal"
                >
                  <i class="ri-add-line align-bottom me-1"></i> Créer une
                  catégorie
                </button>
                <!-- <button type="button" class="btn btn-info ms-1">
                  <i class="ri-file-download-line align-bottom me-1"></i> Importer
                </button> -->
              </div>
            </div>
          </div>
          <div
            class="card-body pt-4 border border-dashed border-end-0 border-start-0"
          >
            <div>
              <div class="table-responsive table-card mb-1">
                <table class="table table-nowrap align-middle" id="orderTable">
                  <thead class="text-muted table-light">
                    <tr class="text-uppercase">
                      <th scope="col" style="width: 25px">
                        <div class="form-check">
                          <input
                            class="form-check-input"
                            type="checkbox"
                            id="checkAll"
                            value="option"
                          />
                        </div>
                      </th>
                      <th class="sort" data-sort="id">ID Catégorie</th>
                      <th class="sort" data-sort="customer_name">Nom</th>
                      <th class="sort" data-sort="product_name">Formations</th>
                      <!-- <th class="sort" data-sort="factor">Position</th> -->
                      <th class="sort" data-sort="city">Actions</th>
                    </tr>
                  </thead>
                  <tbody
                    class="list form-check-all"
                    v-for="(item, index) of categories"
                    :key="index"
                  >
                    <tr>
                      <th scope="row">
                        <div class="form-check">
                          <input
                            class="form-check-input"
                            type="checkbox"
                            name="chk_child"
                            value="option1"
                          />
                        </div>
                      </th>
                      <td class="id">
                        {{ item.id }}
                      </td>
                      <td class="customer_name">{{ item.name }}</td>
                      <td class="amount">{{ item.products_count }} formations</td>
                      <!-- <td>
                        <div class="d-flex factor-field">
                          <button
                            class="btn btn-secondary"
                            v-if="
                              (item.factor && item.factor > 1) ||
                              (!item.factor && item.id > 1)
                            "
                            @click="updateFactor(item.id, 'up')"
                          >
                            <i class="bx bx-up-arrow"></i>
                          </button>
                          <div class="mt-1 mb-1">
                            {{ item.factor || item.id }}
                          </div>
                          <button
                            class="btn btn-secondary"
                            v-if="
                              (item.factor &&
                                item.factor < categories.length) ||
                              (!item.factor && item.id < categories.length)
                            "
                            @click="updateFactor(item.id, 'down')"
                          >
                            <i class="bx bx-down-arrow"></i>
                          </button>
                        </div>
                      </td> -->
                      <td>
                        <ul class="list-inline hstack gap-2 mb-0">
                          <li
                            class="list-inline-item edit"
                            data-bs-toggle="tooltip"
                            data-bs-trigger="hover"
                            data-bs-placement="top"
                            title="Edit"
                          >
                            <a
                              class="text-primary d-inline-block edit-item-btn"
                              data-bs-toggle="modal"
                              href="#EditModal"
                              @click="editdata(item)"
                            >
                              <i class="ri-pencil-fill fs-16"></i>
                            </a>
                          </li>
                          <li
                            class="list-inline-item"
                            data-bs-toggle="tooltip"
                            data-bs-trigger="hover"
                            data-bs-placement="top"
                            title="Remove"
                          >
                            <a
                              class="text-danger d-inline-block remove-item-btn"
                              @click="deletedata(item)"
                            >
                              <i class="ri-delete-bin-5-fill fs-16"></i>
                            </a>
                          </li>
                        </ul>
                      </td>

                      <!-- </div> -->
                    </tr>
                  </tbody>
                </table>
                <div class="noresult" style="display: none">
                  <div class="text-center">
                    <lottie
                      class="avatar-xl"
                      colors="primary:#121331,secondary:#08a88a"
                      :options="defaultOptions"
                      :height="75"
                      :width="75"
                    />
                    <h5 class="mt-2">Pardon! Aucun résultat trouvé</h5>
                    <p class="text-muted">
                      Nous avons recherché plus de 150 commandes Nous n'en avons
                      trouvé aucune commandes pour votre recherche.
                    </p>
                  </div>
                </div>
              </div>
              <div class="d-flex justify-content-end mt-3">
                <div class="pagination-wrap hstack gap-2">
                  <a
                    class="page-item pagination-prev disabled"
                    href="#"
                    v-if="page != 1"
                    @click="page--"
                  >
                    Precedent
                  </a>
                  <ul class="pagination listjs-pagination mb-0">
                    <li
                      v-for="(pageNumber, index) in pages.slice(
                        page - 1,
                        page + 5
                      )"
                      :key="index"
                      @click="page = pageNumber"
                      :class="{
                        active: pageNumber == page,
                        disabled: pageNumber == '...',
                      }"
                    >
                      <a class="page" href="#">{{ pageNumber }}</a>
                    </li>
                  </ul>
                  <a
                    class="page-item pagination-next"
                    href="#"
                    @click="page++"
                    v-if="page < pages.length"
                  >
                    Suivant
                  </a>
                </div>
              </div>
            </div>

            <div
              class="modal fade"
              id="showModal"
              tabindex="-1"
              aria-hidden="true"
            >
              <div class="modal-dialog modal-dialog-centered">
                <div class="modal-content">
                  <div class="modal-header bg-light p-3">
                    <h5 class="modal-title" id="exampleModalLabel">
                      Ajouter une catégorie
                    </h5>
                    <button
                      type="button"
                      class="btn-close"
                      data-bs-dismiss="modal"
                      aria-label="Close"
                      id="close-modal"
                    ></button>
                  </div>
                  <form action="#" id="createform">
                    <div class="modal-body">
                      <input type="hidden" id="id-field" />

                      <div>
                        <label for="delivered-status" class="form-label"
                          >Nom de la catégorie</label
                        >
                        <input
                          class="form-control"
                          type="text"
                          data-trigger
                          name="delivered-status"
                          id="createname"
                        />
                      </div>
                    </div>
                    <div class="modal-footer">
                      <div class="hstack gap-2 justify-content-end">
                        <button
                          type="button"
                          class="btn btn-light"
                          data-bs-dismiss="modal"
                          id="createclosemodal"
                        >
                          Fermer
                        </button>
                        <button
                          type="button"
                          class="btn btn-success"
                          id="add-btn"
                          @click="createcategory"
                        >
                          Enregistrer
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>

            <div
              class="modal fade"
              id="EditModal"
              tabindex="-1"
              aria-labelledby="exampleModalLabels"
              aria-hidden="true"
            >
              <div class="modal-dialog modal-dialog-centered">
                <div class="modal-content">
                  <div class="modal-header bg-light p-3">
                    <h5 class="modal-title" id="exampleModalLabel">
                      Editer une catégorie
                    </h5>
                    <button
                      type="button"
                      class="btn-close"
                      data-bs-dismiss="modal"
                      aria-label="Close"
                      id="close-modal"
                    ></button>
                  </div>
                  <form action="#" id="editform">
                    <div class="modal-body">
                      <input type="hidden" id="id-field" />

                      <div class="mb-3 d-none" id="modal-id">
                        <label for="categoryId" class="form-label">ID</label>
                        <input
                          type="text"
                          pattern="\d*"
                          id="edtcategoryId"
                          class="form-control"
                          placeholder="ID"
                          maxlength="3"
                          readonly
                        />
                      </div>

                      <div>
                        <label for="delivered-status" class="form-label"
                          >Nom de la catégorie</label
                        >
                        <input
                          class="form-control"
                          type="text"
                          data-trigger
                          name="delivered-status"
                          id="editname"
                        />
                      </div>
                    </div>
                    <div class="modal-footer">
                      <div class="hstack gap-2 justify-content-end">
                        <button
                          type="button"
                          class="btn btn-light"
                          data-bs-dismiss="modal"
                          id="edtclosemodal"
                        >
                          Fermer
                        </button>
                        <button
                          type="button"
                          class="btn btn-success"
                          id="add-btn"
                          @click="updatecategory"
                        >
                          Modifier
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!--end col-->
    </div>
    <!--end row-->
  </Layout>
</template>

<style scoped>
.factor-field {
  flex-direction: column;
  justify-content: center;
  width: 50px;
  align-items: center;
}
</style>
